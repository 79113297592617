export const dataflowDataReducer = (state, { type, payload }) => {
  switch (type) {
    case 'INITIAL_LOAD':
      return { ...state, ...payload };

    case 'CHECK_SELECTED_DATE':
      return {
        ...state,
        isSameExpirationDate: payload.isSameExpirationDate
      };

    case 'SET_DATA_PROVIDER_SELECTED':
      return {
        ...state,
        dataProviderSelected: payload
      };

    case 'SET_DUE_DATE':
      return {
        ...state,
        dataCollectionDueDate: payload.dueDate
      };

    case 'SET_HAS_REPRESENTATIVES_WITHOUT_DATASETS':
      return { ...state, hasRepresentativesWithoutDatasets: payload.hasRepresentativesWithoutDatasets };

    case 'SET_FORM_HAS_LEAD_REPORTERS':
      return { ...state, formHasLeadReporters: payload.formHasLeadReporters };

    case 'SET_REPRESENTATIVES_IMPORT':
      return { ...state, representativesImport: payload };

    case 'SET_SHOW_PUBLIC_INFO':
      return { ...state, showPublicInfo: payload.showPublicInfo };

    case 'LOAD_PERMISSIONS':
      return {
        ...state,
        hasWritePermissions: payload.hasWritePermissions,
        hasCustodianPermissions: payload.hasCustodianPermissions,
        isCustodian: payload.isCustodian,
        isObserver: payload.isObserver,
        isAdmin: payload.isAdmin,
        userRoles: payload.userRoles,
        isNationalCoordinator: payload.isNationalCoordinator
      };

    case 'MANAGE_DIALOGS':
      return {
        ...state,
        [payload.dialog]: payload.value,
        [payload.secondDialog]: payload.secondValue,
        deleteInput: payload.deleteInput
      };

    case 'ON_EDIT_DATA':
      return {
        ...state,
        description: payload.description,
        isCitizenScienceDataflowDialogVisible: payload.isCitizenScienceDataflowDialogVisible,
        isReportingDataflowDialogVisible: payload.isReportingDataflowDialogVisible,
        isExportDialogVisible: payload.isExportDialogVisible,
        name: payload.name
      };

    case 'SET_DATA_PROVIDER_ID':
      return { ...state, dataProviderId: payload.id };

    case 'SET_DESIGN_DATASET_SCHEMAS':
      return { ...state, designDatasetSchemas: payload.designDatasets };

    case 'SET_IS_COPY_DATA_COLLECTION_TO_EU_DATASET_LOADING':
      return { ...state, isCopyDataCollectionToEUDatasetLoading: payload.isLoading };

    case 'SET_IS_DATA_SCHEMA_CORRECT':
      return { ...state, isDataSchemaCorrect: payload.validationResult };

    case 'SET_IS_DATA_UPDATED':
      return { ...state, isDataUpdated: !state.isDataUpdated };

    case 'SET_IS_DOWNLOADING_USERS':
      return { ...state, isDownloadingUsers: payload };

    case 'SET_IS_EXPORT_EU_DATASET':
      return { ...state, isExportEUDatasetLoading: payload.isExportEUDatasetLoading };

    case 'SET_IS_PAGE_LOADING':
      return { ...state, isPageLoading: payload.isPageLoading };

    case 'SET_IS_RIGHT_PERMISSIONS_CHANGED':
      return {
        ...state,
        isRightPermissionsChanged: payload.isRightPermissionsChanged
      };

    case 'SET_UPDATED_DATASET_SCHEMA':
      return { ...state, updatedDatasetSchema: payload.updatedData };

    case 'SET_HAS_REPORTERS':
      return { ...state, hasReporters: payload.hasReporters };

    case 'SET_IS_RECEIPT_LOADING':
      return { ...state, isReceiptLoading: payload.isReceiptLoading };

    case 'SET_IS_RECEIPT_OUTDATED':
      return { ...state, isReceiptOutdated: payload.isReceiptOutdated };

    case 'SET_IS_RELEASABLE':
      return { ...state, isReleasable: payload.isReleasable };

    case 'SET_IS_FETCHING_DATA':
      return { ...state, isFetchingData: payload.isFetchingData };

    case 'SET_IS_EXPORTING':
      return { ...state, isExporting: payload };

    case 'SET_ALL_REPORTERS_DELETED':
      return { ...state, allReportersDeleted: payload.allReportersDeleted };

    case 'SET_IS_DELETING_ALL_REPORTERS':
      return { ...state, isDeletingAllReporters: payload.isDeletingAllReporters };

    case 'SET_IS_UPDATING_PERMISSIONS':
      return { ...state, isUpdatingPermissions: payload.isUpdatingPermissions };

    case 'SET_REPRESENTATIVE':
      return { ...state, representative: payload };

    case 'SET_RESTRICT_FROM_PUBLIC':
      return { ...state, restrictFromPublic: payload };

    case 'SET_AUTOMATIC_REPORTING_DELETION':
      return { ...state, isAutomaticReportingDeletion: payload.isAutomaticReportingDeletion };

    case 'RESTRICT_FROM_PUBLIC_IS_UPDATING':
      return {
        ...state,
        restrictFromPublicIsUpdating: {
          value: payload.value,
          dataProviderId: payload.dataProviderId
        }
      };

    case 'SHOW_PUBLIC_INFO_IS_UPDATING':
      return { ...state, isShowPublicInfoUpdating: payload.isShowPublicInfoUpdating };

    case 'ON_CLEAN_UP_RECEIPT':
      return { ...state, ...payload };

    case 'RELEASE_IS_CREATING':
      return { ...state, isReleaseCreating: payload.value };

    default:
      return state;
  }
};
